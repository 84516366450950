import { FunctionComponent } from "react";

const Avatar: FunctionComponent = () => {
	return (
		<div className='avatar'>
			<div className='avatar-img' />
		</div>
	);
};

export default Avatar;
